/*
 * 登录与注册弹窗
 */
import React from 'react';
import './index.scss';
import lightToast from '@controls/lightToast';
import ForgetPwd from '@components/forgetPwd';
import request from "@common/request";
import { debounce } from '@common/util';

export default class extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      forgetPwdFlag: false, // 是否点击忘记密码
      tabType: 'login', //login regist  // tab type
      loginType: 1, // 密码or验证码登录, 1:密码登录

      sending: false,
      second: 60,
      smsTxt: '获取验证码'
    }

    this.mobile = '';
    this.passwd = '';
    this.code = '';
    this.regMobile = '';
    this.rePasswd = '';

    this.phoneReg = /^1\d{10}$/
    this.passwdReg = /^[a-zA-Z0-9]{6,30}$/
    this.codeReg = /^\d{6}$/
    this.clickRegist = debounce(this.clickRegist).bind(this)
    this.getVertiCode = this.getVertiCode.bind(this)

  }

  //
  countDown () {
    const { second } = this.state
    if (second > 0) {
      let time = second
      time--
      this.setState({
        smsTxt: `${time}s后获取`,
        second: time
      })
      this.timer = setTimeout(() => {
        this.countDown()
      }, 1000)
    } else {
      clearTimeout(this.timer)
      this.setState({
        second: 60,
        sending: false,
        smsTxt: "获取验证码",
      })
    }
  }

  // 获取验证码
  async getVertiCode () {
    const { sending, tabType } = this.state;
    let resp;
    let mobile = tabType === 'login' ? this.mobile : this.regMobile;
    let scene = tabType === 'login' ? 100 : 101;
    if (sending) return;

    if (!this.phoneReg.test(mobile)) {
      return lightToast('请输入正确手机号');
    }

    try {
      resp = await request({
        method: 'post',
        url: '/gateway/route?action=sms.code.get',
        data: {
          mobile: parseInt(mobile),
          scene,
          check_mobile: 0
        },
      });
    } catch (err) {
      console.log(err)
      return;
    }

    if (resp && resp.code === 0) {
      lightToast('验证码发送成功')
      this.setState({
        sending: true
      })
      this.countDown();

    } else {
      lightToast(resp.message || '验证码发送失败，请检查手机号');
      this.setState({
        sending: false,
      });
    }
  }

  // 切换tab
  tabChange = (tabType) => {
    this.setState({
      tabType,
      forgetPwdFlag: false
    })
  }

  // 关闭弹窗
  close = () => {
    this.props.close && this.props.close();
  }

  // 重置密码后重新登录
  reLogin = () => {
    this.setState({
      forgetPwdFlag: false
    });
  }


  // 输入
  handleInput (inputType, e) { //mobile, code , passwd
    let { value } = e.target;
    this[inputType] = value.trim();
  }

  //点击登录
  async clickLogin () {
    let type = 1; //1 密码登录
    let params = {
      mobile: parseInt(this.mobile),
      type
    }

    if (!this.phoneReg.test(this.mobile)) {
      return lightToast('请输入正确手机号');
    }

    if (type === 1) {
      if (!this.passwdReg.test(this.passwd)) {
        return lightToast('请输入正确密码')
      }
    } else {
      if (!this.codeReg.test(this.code)) {
        return lightToast('请输入六位数字的验证码')
      }
    }

    if (type === 1) {
      params.passwd = this.passwd;
    } else {
      params.code = this.code;
    }

    request({
      method: 'post',
      url: '/gateway/route?action=student.login',
      data: params,
    }).then(resp => {
      if (resp.code !== 0) {
        return lightToast(resp.message || '输入有误');
      }

      lightToast(resp.message || '登录成功');

      let usIf = {
        phoneNo: this.mobile,
        sid: resp.data && resp.data.sid
      };

      localStorage.setItem('localUserInfo', JSON.stringify(usIf));

      this.props.updeteUserInfo && this.props.updeteUserInfo(usIf);

      setTimeout(() => {
        this.close();
      }, 800);

    }).catch(err => {
      lightToast(err && err.message || '请求出错，请稍后再试~');
    });

  }

  // 点击注册
  async clickRegist () {
    let resp;

    try {
      resp = await request({
        method: 'post',
        url: '/gateway/route?action=student.register',
        data: {
          reg_type: 1,
          mobile: parseInt(this.regMobile),
          code: this.code,
          passwd: this.rePasswd,
          reg_from: 0  //  0官网 1活动 2代理
        },
      });
    } catch (err) {
      return lightToast(err && err.message || '注册失败');
    }

    let { code, data, message } = resp || {};
    if (code === 0) {
      lightToast(message || '注册成功');

      // 注册成功，ji 登录成功

      let usIf = {
        phoneNo: this.regMobile,
        sid: data.sid
      };

      localStorage.setItem('localUserInfo', JSON.stringify(usIf));

      this.props.updeteUserInfo && this.props.updeteUserInfo(usIf);

      setTimeout(() => {
        this.close();
      }, 800);
    }
  }

  // 忘记密码
  clickForgetPwd = () => {
    this.setState({
      forgetPwdFlag: true
    });
  }
  contentFilter = (content) => {
    if (content) {
      const Content = content.split(' ');
      console.log(Content, 'Content')
      return `
            ${Content[0]}
            ${Content[1]}
            `
    }
    return;
  }
  render () {
    let { forgetPwdFlag, tabType, smsTxt, sending } = this.state;
    let { visible } = this.props;
    let itemInfo = this.props.itemInfo || {};

    if (!visible) return null

    return (
      <>
        <div className="dCtnOuter">
          <div className="dCtn">
            <img className="close"
              src={require('../../images/close_icon2x.png')}
              onClick={this.close} />
            {
              // itemInfo.course_type 4 付费体验课,3 付费正式课
              itemInfo.course_type === 4 ? <div className="leftC">
                <div className="lTop">
                  <span>￥</span><span class="bigPrice">{itemInfo.sale_price}</span><span>&#x2F;{itemInfo.cls_hour || 3}课时</span>
                </div>
                <div className="lCenter">
                  {!itemInfo.remark ? '' : itemInfo.remark[0]}<br />{!itemInfo.remark ? '' : itemInfo.remark[1]}
                </div>
              </div> : <div className="leftC">
                <div className="lTop">
                  <span>￥</span><span class="bigPrice">{itemInfo.sale_price}</span><span>&#x2F;{itemInfo.cls_hour}课时</span>
                  {/* <div className="preferential">限时优惠</div> */}
                </div>
                <div className="lCenter">
                  {!itemInfo.remark ? '' : itemInfo.remark[0]}<br />{!itemInfo.remark ? '' : itemInfo.remark[1]}
                </div>
                {/* <div className="lBtm">原价{itemInfo.sale_price || 2800}</div> */}
              </div>
            }

            <div className="rightC">
              <div className="tab">
                <div className={`tabBtn ${tabType === 'login' ? 'active' : ''}`} onClick={this.tabChange.bind(this, 'login')}>登录</div>
                <div className={`tabBtn ${tabType === 'regist' ? 'active' : ''}`} onClick={this.tabChange.bind(this, 'regist')}>注册</div>
              </div>
              {tabType === 'login' ?
                forgetPwdFlag ? <ForgetPwd reLogin={this.reLogin} /> : <div>
                  <div class="phoneD">
                    <img src={require('../../images/icon_iphone2x.png')} />
                    <input type="number" placeholder="手机号"
                      onInput={this.handleInput.bind(this, 'mobile')} />
                  </div>
                  <div class="pwd">
                    <img src={require('../../images/icon_key2x.png')} />
                    <input placeholder="密码"
                      onInput={this.handleInput.bind(this, 'passwd')} />
                  </div>
                  <div className="forgetPwd" onClick={this.clickForgetPwd}>忘记密码</div>
                  <div class="regBtn" onClick={this.clickLogin.bind(this)}>登录</div>
                </div> : <div>
                  <div class="phoneD">
                    <img src={require('../../images/icon_iphone2x.png')} />
                    <input type="number" placeholder="手机号"
                      onInput={this.handleInput.bind(this, 'regMobile')} />
                  </div>
                  <div class="aboutPhone">
                    <div class="vertifiCode">
                      <img src={require('../../images/icon_code2x.png')} />
                      <input type="text" placeholder="验证码"
                        onInput={this.handleInput.bind(this, 'code')} />
                    </div>
                    <div class={`getVertiCode ${sending ? 'gray' : ''}`} onClick={this.getVertiCode}>{smsTxt || '获取验证码'}</div>
                  </div>
                  <div class="pwd">
                    <img src={require('../../images/icon_key2x.png')} />
                    <input type="text" placeholder="密码" onInput={this.handleInput.bind(this, 'rePasswd')} />
                  </div>
                  <div class="regBtn" onClick={this.clickRegist}>注册</div>
                </div>
              }
            </div>
          </div>
        </div>
      </>
    )
  }
}
