import React, { Component } from 'react';
import { HashRouter, Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';
import asyncComponent from '@components/asyncComponent';

import home from "@view/home.jsx";
import download from "@view/download.jsx";
// const download = asyncComponent(() => import("@view/download"));
// const page2 = asyncComponent(() => import("@view/donghuaDemo"));

export default class RouteConfig extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={home} />
          <Route path="/download" exact component={download} />
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    )
  }
}
