/*global NODE_ENV*/
/*
 * 官网首页
 */
import React from 'react'
import '@scss/home';
import { teacherArr, parentArr } from '@common/info';
import { getUserInfo, judgeToMobile } from '@common/util';
import LoginReg from '@components/LoginReg';
import BuyCurse from '@components/buyCurse';
import request from "@common/request";

judgeToMobile()
export default class Home extends React.Component {
  constructor() {
    super()
    this.state = {
      userInfo: {},
      iid: '', // 去付款时候的商品id
      scrollTop: 0,
      teacherIndex: 0, // 老师显示的下标
      parentIndex: 0, // 家长显示的下标
      auditionFixed: false, // 底部试听的广告 是否 fixed
      buyNum: 0, // 购买类型，25 1800
      loginDgVisible: true, // 登录弹窗是否显示
      exp_info: {}, // 体验课
      regular_info: {} // 常规课
    }

    this.initData = {
      userInfo: {},
      iid: '', // 去付款时候的商品id
      scrollTop: 0,
      teacherIndex: 0, // 老师显示的下标
      parentIndex: 0, // 家长显示的下标
      auditionFixed: false, // 底部试听的广告 是否 fixed
      buyNum: 0, // 购买类型，25 1800
      loginDgVisible: true, // 登录弹窗是否显示
      exp_info: {}, // 体验课
      regular_info: {} // 常规课
    }

    this.fixELe = React.createRef();
  }

  //登录成功后更新用户信息
  updeteUserInfo = (userInfo) => {
    this.setState({
      userInfo
    });
  }

  // 设置登录是否可见
  setLogDgVisible = () => {
    let userInfo = getUserInfo();
    this.setState({
      loginDgVisible: !userInfo.phoneNo,
      userInfo
    });
  }

  // 处理底部广告
  dealWithFixEle = () => {
    let { auditionFixed } = this.state;
    if (this.isInSight()) {

      auditionFixed && this.setState({
        auditionFixed: false
      })
    } else {
      !auditionFixed && this.setState({
        auditionFixed: true
      });
    }
  }

  handleScroll = (e) => {
    let { scrollTop } = this.state;
    let prevScrollTop = scrollTop;
    let curScrollTop = document.documentElement.scrollTop;

    if (curScrollTop === 0) {
      this.setState({
        scrollTop: 0
      });
      return
    }

    this.dealWithFixEle()

    // if ( prevScrollTop > 0) {
    //   return
    // }

    // if ( curScrollTop > 0 ) {
    //   this.setState({
    //     scrollTop: curScrollTop
    //   });
    // }

    if (prevScrollTop > 0) return;

    curScrollTop > 0 && this.setState({ scrollTop: curScrollTop });
  }

  componentDidMount () {
    this.getGoodsInfo();
    this.setLogDgVisible();

    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnMount () {
    window.removeEventListener('scroll', this.handleScroll);
  }

  //获取商品信息接口
  async getGoodsInfo () {
    let resp;
    try {
      resp = await request({
        method: 'post',
        url: '/gateway/route?action=goods.info'
      });
    } catch (err) {
      console.log(err)
      return;
    }

    let { code, data } = resp || {};
    if (code !== 0) {
      return;
    }
    let { exp_info = {}, regular_info = {} } = data;
    exp_info.sale_price = (exp_info.sale_price || 0) / 100;
    exp_info.origin_price = (exp_info.origin_price || 0) / 100;
    exp_info.remark = this.contentFilter(exp_info.remark)
    regular_info.sale_price = (regular_info.sale_price || 0) / 100;
    regular_info.origin_price = (regular_info.origin_price || 0) / 100;
    regular_info.remark = this.contentFilter(regular_info.remark)
    this.setState({
      exp_info,
      regular_info
    });
  }

  // 页面中点击立即购买(优惠)
  clickBuy25 = () => {
    // return lightToast('暂时未开放，敬请期待')

    this.setState({
      buyNum: 25,
    });
  }

  //正常订购
  clickBuy1800 = () => {
    // return lightToast('暂时未开放，敬请期待')
    this.setState({
      buyNum: 1800,
    });
  }

  // 切换老师
  clickTleft = () => {
    let { teacherIndex } = this.state;

    this.setState({
      teacherIndex: teacherIndex === 0 ? teacherIndex = teacherArr.length - 1 : --teacherIndex
    });
  }

  // 切换老师
  clickTright = () => {
    let { teacherIndex } = this.state;

    this.setState({
      teacherIndex: teacherIndex === teacherArr.length - 1 ? 0 : ++teacherIndex
    });

  }

  // 切换家长
  clickPleft = () => {
    let { parentIndex } = this.state;

    this.setState({
      parentIndex: parentIndex === 0 ? parentIndex = parentArr.length - 1 : --parentIndex
    });
  }

  //切换家长
  clickPright = () => {
    let { parentIndex } = this.state;

    this.setState({
      parentIndex: parentIndex === parentArr.length - 1 ? 0 : ++parentIndex
    });
  }

  //是否在视窗内
  isInSight = () => {
    // left, top, right, bottom
    // const rect = this.fixELe.current.getBoundingClientRect();
    let $ele = document.querySelector('.footer');
    if (!$ele) {
      return false
    }
    const rect = $ele.getBoundingClientRect();

    return !(
      rect.top > window.innerHeight // top 不在视窗
      || rect.bottom < 0 // bottom 不在
      || rect.left > window.innerWidth
      || rect.right < 0
    );
  }

  // 老师信息
  getTeacherInfo = () => {
    let { teacherIndex } = this.state;
    let item = teacherArr[teacherIndex];

    return <div class="tCenter">
      <div>
        <img class="tImg" src={require(`../images/teacher/${++teacherIndex}-${item.name}.png`)} />
      </div>
      <div class="tName">
        {item.name}
      </div>
      <div class="tDesc">
        {
          item.descArr.map(itm => {
            if (itm.special) {
              return <span class="specailFont">{itm.txt}</span>
            }
            return <span>{itm.txt}</span>
          })
        }
      </div>
    </div>
  }

  // 家长信息
  getParentInfo = () => {
    let { parentIndex } = this.state;
    let item = parentArr[parentIndex];
    return <div class="pCenter">
      <div>
        <img class="pImg" src={require(`../images/parent/${++parentIndex}-${item.name}.png`)} />
      </div>
      <div class="pName">
        {item.name}
      </div>
      <div class="pDesc">
        {
          item.descArr.map(itm => {
            if (itm.special) {
              return <span class="specailFont">{itm.txt}</span>
            }
            return <span>{itm.txt}</span>
          })
        }
      </div>
    </div>
  }

  // 顶部的立即购买点击，跳转到双价区域
  topClickbuy = () => {
    // let $dom = document.querySelector(`.buyCurse`);
    let $dom = document.querySelector(`.scroll_target`);
    $dom && $dom.scrollIntoView();
  }

  // 头部信息
  getHeaderTmp = () => {
    let { scrollTop } = this.state;
    return <div className={`header ${scrollTop > 0 ? 'header-scroll' : ''} `}>
      <div class="headInner">
        <div class="logoD">
          <img class="logo" src={require('../images/LOGO@2x.png')} />
        </div>
        <div class="topNav">
          <a href="/"><div class="nav" onClick={this.toHomePage} >首页</div></a>
          <a href="/download"><div class="nav">软件下载</div></a>
          {/* <a href="#/findMiddle"><div class="nav">代理招募</div></a> */}
          {/* <a href="/comBg.html"><div class="nav">关于我们</div></a> */}
        </div>
        {/* 暂时去掉入口 */}
        {/* <div class="buyBtn" onClick={this.topClickbuy} >立即购买</div> */}
      </div>
    </div>
  }

  //返回
  goback = () => {
    this.setState({
      buyNum: 0
    });
  }

  // 因未登录，提交时重新打开登录弹窗
  popLoginDialog = () => {
    this.setState({
      loginDgVisible: true
    });
  }

  // 关闭登录注册弹窗
  closeLoginDg = () => {
    this.setState({
      loginDgVisible: false
    });
  }

  // home页
  toHomePage = () => {
    this.setState({
      ...this.initData
    });
  }

  contentFilter = (content) => {
    if (content) {
      const Content = content.split(' ');
      return Content;
    }
    return;
  }

  render () {
    let { userInfo, buyNum, loginDgVisible, exp_info = {}, regular_info = {} } = this.state;
    let itemInfo = buyNum === 25 ? exp_info : regular_info;
    let headerTmp = this.getHeaderTmp();
    if (buyNum > 0) {
      return <div class="container">
        {headerTmp}
        <BuyCurse
          goback={this.goback}
          popLoginDialog={this.popLoginDialog}
          userInfo={userInfo}
          itemInfo={itemInfo || {}} />
        <LoginReg
          updeteUserInfo={this.updeteUserInfo}
          visible={loginDgVisible}
          close={this.closeLoginDg}
          itemInfo={itemInfo} />
      </div>
    }

    return <div class="container">
      {headerTmp}
      <div class="topCtn"><img class="topTxtDesc" src={require('../images/topTxtDesc.png')} /></div>

      {/* <div><img class="quweiketang" src={require('../images/quweiketang.png')}/></div>
      <div><img class="quweiketang" src={require('../images/duibi.png')}/></div>
      <div><img class="quweiketang" src={require('../images/kechengtixi.png')}/></div> */}
      <div>
        <img class="kouyuke" src={require('../images/kouyuke.png')} />
      </div>

      <div className="whykouyupeilian">
        <img src={require('../images/whykouyupeilian.png')} />
      </div>

      <div>
        <img class="buchong" src={require('../images/buchong.png')} />
      </div>

      <div className="chara">
        <img src={require('../images/tedian.png')} />
      </div>

      <div className="peiliantch">
        <img src={require('../images/peiliantch.png')} />
      </div>

      {/* <div class="teacherCtn">
        <div><img className="renzheng" src={require('../images/renzheng.png')}/></div>
        <div class="teacher">
          <div class="tLeft"><img src={require('../images/arrowLeft.png')} onClick={this.clickTleft}/></div>
          {this.getTeacherInfo()}
          <div class="tRight"><img src={require('../images/arrowRight.png')} onClick={this.clickTright}/></div>
        </div>
        <img class="yinhao" src={require('../images/yinhao.png')} />
      </div> */}
      <div class="buyCurse" id="page-buy">
        <div className="xingjia_d">
          <img className="xingjia" src={require('../images/xingjia.png')} />
          <div className="scroll_target"></div>
        </div>
        {/* 临时加的 */}
        <div className="con"></div>
        {/* <div class="buyD">
          <div class="buyC buyLeft"></div>
          <div class="buyLeftAb" onClick={this.clickBuy25}>
            <div class="bprice">
              <span>￥</span><span class="bigPrice">{exp_info.sale_price || 25}</span><span>&#x2F;{exp_info.cls_hour || 3}课时</span>
            </div>
            <div class="buyTxt">{exp_info.remark || '自购买之日起，课时两周内有效 每期限招100名，先到先得'}</div>
            <div class="buyBtn">立即购买</div>
          </div>
          <div class="buyC buyRight"></div>
          <div class="buyRightAb" onClick={this.clickBuy1800}>
            <div class="bprice">
              <span>￥</span><span class="bigPrice">{regular_info.sale_price || 1800}</span><span>&#x2F;{regular_info.cls_hour || 60}课时</span>
              <div className="preferential">限时优惠</div>
            </div>
            <div class="buyTxt">{regular_info.remark || '自购买之日起，课时一年内有效 每周3课时'}</div>
            <div class="formPrice">原价￥{regular_info.origin_price || 2800}</div>
            <div class="buyBtn">立即购买</div>
          </div>
        </div> */}

        {/* <div class="buyD">
          <div class="buyC buyLeft"></div>
          <div class="buyLeftAb" onClick={this.clickBuy1800}>
            <div class="bprice">
              <span>￥</span><span class="bigPrice">{regular_info.sale_price}</span><span>&#x2F;{regular_info.cls_hour || 96}课时</span>
            </div>
            <div class="buyTxt">{!regular_info.remark ? '' : regular_info.remark[0]}<br />{!regular_info.remark ? '' : regular_info.remark[1]}</div>
            <div class="buyBtn">立即购买</div>
          </div>
          <div class="buyC buyRight"></div>
          <div class="buyRightAb" onClick={this.clickBuy25}>
            <div class="bprice">
              <span>￥</span><span class="bigPrice">{exp_info.sale_price}</span><span>&#x2F;{exp_info.cls_hour || 96}课时</span>
            </div>
            <div class="buyTxt">{!exp_info.remark ? '' : exp_info.remark[0]}<br />{!exp_info.remark ? '' : exp_info.remark[1]}</div>
            <div class="buyBtn">立即购买</div>
          </div>
        </div> */}
      </div>
      <div class="parentCtn">
        <div><img className="parSay" src={require('../images/parSay.png')} /></div>
        <div class="parent">
          <div class="pLeft">
            <img src={require('../images/arrowLeft.png')} onClick={this.clickPleft} />
          </div>
          {this.getParentInfo()}
          <div class="pRight">
            <img src={require('../images/arrowRight.png')} onClick={this.clickPright} />
          </div>
        </div>
        <img class="yinhao" src={require('../images/yinhao.png')} />
      </div>

      <div class="footer" ref={this.fixELe}>
        {/* <div class="fempty"></div>
        <div class={`xuanfu animate ${ this.state.auditionFixed ? 'fixed' : '' } `}
        >
          <img src={require('../images/fixBtm.png')}/>
          <div className="hotBtn" onClick={this.clickBuy25}></div>
        </div> */}
        <div class="footerInner">
          <div class="fLeft">
            <div><img className="logoB" src={require('../images/redLogo@2x.png')} /></div>
            <div><img className="weixin" src={require('../images/weixin.png')} /></div>
          </div>
          <div class="fRight">
            <div class="aboutMe">
              <div>大枣口语陪练</div>
              <a><div>首页</div></a>
              <a href="/download"><div>软件下载</div></a>
              {/* <a><div>代理招募</div></a> */}
              {/* <a href="/comBg.html"><div>关于我们</div></a> */}
            </div>
            <div class="partner">
              <div>合作伙伴</div>
              <a href="https://www.abc360.com/" target="_blank"><div>abc360英语</div></a>
              <a href="https://www.landi.com/" target="_blank"><div>兰迪少儿英语</div></a>
            </div>
            <div class="connectUs">
              <div>联系我们</div>
              {/* <div>客服电话：0571-22931337（工作日09:00-21:00）</div> */}
              <div>杭州市滨江区江虹路459号英飞特大厦A座13楼</div>
            </div>
          </div>
          <div class="clear"></div>
          {/* 暂时去掉 */}
          <div class="copyRight">
            <div class="hrHor">
            </div>
            <div class="cpLeft">
              <div>Copyright © 2020 杭州瓜蕉科技有限公司 All Rights Reserved.</div>
              <div className="beiancode">
                <a target="_blank" href="https://beian.miit.gov.cn">浙ICP备19047149号-1</a>
                <div className="beian">
                  <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802010959" className="beian_a">
                    <img src={require(`../images/beian.png`)} className="beian_img" />
                    <p className="beian_txt">浙公网安备 33010802010959号</p>
                  </a>
                </div>
              </div>
            </div>
            {/* <div class="cpRight">
              <span>中国认证联盟认证</span>
              <span>网站信用认证</span>
              <span>安全联盟认证</span>
              <span>浙公网安备 33010802003394号</span>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  }
}
