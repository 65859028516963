import React from 'react'
import * as ReactDOM from 'react-dom'
import './index.scss'

let toast = (content = '', timeout = 3000, cb = () => {}) => {
  class Toast {
    constructor() {
      this.timer = null
      this.show()
    }

    show() {
      const container = document.createElement('div')
      container.className = 'dazao-alert'
      document.body.appendChild(container)
      ReactDOM.render(
        <div className="alert-wrap" onClick={this.destory.bind(this)}>
          <p className="content">{content}</p>
        </div>, container)

      this.container = container
      this.timer = setTimeout(() => {
        this.destory()
      }, timeout)
    }

    destory() {
      if (ReactDOM.unmountComponentAtNode(this.container)) {
        document.body.removeChild(this.container)
        if (typeof cb === 'function') {
          cb()
        }
      }
    }
  }

  return new Toast()
}

export default toast
