import axios from 'axios'
import {URL_PREFIX} from './config';

const headers = {
  "x-source": "web-pl-student"
}

const Request = axios.create({
  headers,
  baseURL: URL_PREFIX,
  timeout: 5000
})

Request.interceptors.response.use(
  resp => {
    // resp = {
    //   data: {
    //     code: 0,
    //     data: {},
    //     message: 'xxx成功'
    //   }
    // }

    // status: 200 not confirm
    if (resp && resp.data && resp.data.code === 0) {
      return Promise.resolve(resp.data);
    }
    return Promise.reject(resp.data);
  }
)

export default Request
