import axios from 'axios'

console.log('2021年07月15日15:00:06')
// 测试环境
// const URL_PREFIX = process.env.NODE_ENV === 'development' ? 'https://lesson.release.dazao.com/gw/apis' : '//app.dazao.com/apis';
// const URL_PREFIX = process.env.NODE_ENV === 'development' ? 'https://lesson.release.dazao.com/gw/api/app' : '//app.dazao.com/apis';

// 线上环境
// const URL_PREFIX = process.env.NODE_ENV === 'development' ? 'https://app.dazao.com/apis' : '//app.dazao.com/apis';

// location.host 可能为 dazao.com、 www.dazao.com

const URL_PREFIX = process.env.NODE_ENV === 'development' ? `https://${location.host}/gw/api/app` : `//${location.host || 'dazao.com'}/gw/api/app`;

const headers = {
  "x-source": "web-pl-student"
}

const Request = axios.create({
  headers,
  baseURL: URL_PREFIX,
  timeout: 5000
})

Request.interceptors.response.use(
  resp => {
    // resp = {
    //   data: {
    //     code: 0,
    //     data: {},
    //     message: 'xxx成功'
    //   }
    // }
    // status: 200 not confirm
    if (resp && resp.data && resp.data.code === 200) {
      return Promise.resolve(resp.data);
    }
    return Promise.reject(resp.data);
  }
)

export default Request
