import qs from 'query-string'

export const query = qs.parse(location.search)

export const guid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = Math.random() * 16 | 0,
      v = c == 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

export const totalTime = (total) => {
  const seconds = Math.floor(total % 60),
    minutes = Math.floor(total / 60)
  return (minutes + ':' + seconds).replace(/\b(\d{1})\b/g, (all, m) => '0' + m)
}

export const addClass = (el, className) => {
  const curClass = el.className.split(/\s+/)
  if (curClass.indexOf(className) === -1) {
    curClass.push(className)
    el.className = curClass.join(" ")
  }
}

export const removeClass = (el, className) => {
  const curClass = el.className.split(/\s+/),
    index = curClass.indexOf(className)
  if (index > -1) {
    curClass.splice(index, 1)
    el.className = curClass.join(" ")
  }
}

export const replaceClass = (el, oldClass, newClass) => {
  const curClass = el.className.split(/\s+/),
    index = curClass.indexOf(oldClass)
  if (index > -1) {
    curClass.splice(index, 1, newClass)
  } else {
    curClass.push(newClass)
  }
  el.className = curClass.join(" ")
}

export const toggleClass = (el, className, flag) => {
  if (flag === true) {
    addClass(el, className)
  } else if (flag === false) {
    removeClass(el, className)
  } else {
    const curClass = " " + el.className + " "
    if (curClass.indexOf(" " + className + " ") > -1) {
      addClass(el, className)
    } else {
      removeClass(el, className)
    }
  }
}

export const tsMinSec = (timestamp) => {
  let min = Math.floor(timestamp / 1000 / 60)
  let secs = (timestamp / 1000) % 60
  let minDesc = "" + min
  if (min < 10) {
    minDesc = "0" + minDesc
  }

  let secsDesc = "" + secs
  if (secs < 10) {
    secsDesc = "0" + secsDesc
  }
  return minDesc + ":" + secsDesc
}

export const getOSDetail = () => {
  let sUserAgent = navigator.userAgent
  let isWin = (navigator.platform == "Win32") || (navigator.platform == "Windows")
  let isMac = (navigator.platform == "Mac68K") || (navigator.platform == "MacPPC") || (navigator.platform == "Macintosh") || (navigator.platform == "MacIntel")
  if (isMac) return "Mac"
  if (isWin) {
    let isWin2K = sUserAgent.indexOf("Windows NT 5.0") > -1 || sUserAgent.indexOf("Windows 2000") > -1
    if (isWin2K) return "Win2000"
    let isWinXP = sUserAgent.indexOf("Windows NT 5.1") > -1 || sUserAgent.indexOf("Windows XP") > -1
    if (isWinXP) return "WinXP"
    let isWin2003 = sUserAgent.indexOf("Windows NT 5.2") > -1 || sUserAgent.indexOf("Windows 2003") > -1
    if (isWin2003) return "Win2003"
    let isWinVista = sUserAgent.indexOf("Windows NT 6.0") > -1 || sUserAgent.indexOf("Windows Vista") > -1
    if (isWinVista) return "WinVista"
    let isWin7 = sUserAgent.indexOf("Windows NT 6.1") > -1 || sUserAgent.indexOf("Windows 7") > -1
    if (isWin7) return "Win7"
    let isWin8 = sUserAgent.indexOf("Windows NT 6.2") > -1 || sUserAgent.indexOf("Windows 8") > -1
    if (isWin8) return "Win8"
    let isWin10 = sUserAgent.indexOf("Windows NT 10") > -1 || sUserAgent.indexOf("Windows 10") > -1
    if (isWin10) return "Win10"
  }
  return "other"
}

export const getOS = () => {
  let isWin = (navigator.platform == "Win32") || (navigator.platform == "Windows")
  let isMac = (navigator.platform == "Mac68K") || (navigator.platform == "MacPPC") || (navigator.platform == "Macintosh") || (navigator.platform == "MacIntel")
  if (isMac) return "Mac"
  if (isWin) return 'Win'
  return 'other'
}

const datePatterns = {
  yyyy(dateObj) {
    return '' + dateObj.getFullYear()
  },
  MM(dateObj) {
    return preFillZero(dateObj.getMonth() + 1)
  },
  dd(dateObj) {
    return preFillZero(dateObj.getDate())
  },
  HH(dateObj) {
    return preFillZero(dateObj.getHours())
  },
  mm(dateObj) {
    return preFillZero(dateObj.getMinutes())
  },
  ss(dateObj) {
    return preFillZero(dateObj.getSeconds())
  }
}

function preFillZero(val) {
  return ('0' + val).slice(-2)
}

export const dateFormat = (dateObj, pattern) => {
  if (!dateObj) {
    return ''
  }
  pattern = pattern || 'yyyy-MM-dd HH:mm:ss'
  Object.keys(datePatterns).forEach(p => {
    pattern = pattern.replace(new RegExp(p, 'g'), datePatterns[p].bind(null, dateObj))
  })
  return pattern
}


/**
*
* @param fn {Function}   实际要执行的函数
* @param delay {Number}  延迟时间，也就是阈值，单位是毫秒（ms）
*
* @return {Function}     返回一个“去弹跳”了的函数
*/
export function debounce(fn, delay = 500) {
  let timer
  return function () {

      let context = this
      let args = arguments
      clearTimeout(timer)

      timer = setTimeout(function () {
          fn.apply(context, args)
      }, delay)
  }
}

/*
 * 获取本地用户的信息
 */

export let getUserInfo = () => {
  let userInfo = localStorage.getItem('localUserInfo') || '{}';

  try {
    userInfo = JSON.parse(userInfo);
  } catch (error) {
    //
    userInfo = {};
  }

  return userInfo;
}

// 是否是移动端
export const isMobile = () => {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
}

// 是否跳转到移动端页面
export let judgeToMobile = () => {
  if (isMobile() ) {
    window.location.href = 'https://mobile.dazao.com'
  }
}
