import React from 'react';
import ReactDOM from 'react-dom';
import Route from './router/';
import '@common/flexible';
import { AppContainer } from 'react-hot-loader';

const render = Component => {
  ReactDOM.render(
    <AppContainer>
      <Component />
    </AppContainer>,
    document.body,
  )
}

render(Route);

// Webpack Hot Module Replacement API
// todo
if (module.hot) {
  module.hot.accept('./router/', () => {
    render(Route);
  })
}
