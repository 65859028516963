// 教师信息
export const teacherArr = [
  {
      name: 'Uki',
      descArr: [
        {txt: 'Uki老师拥有'},
        {txt: "Bachelor's", special: true },
        {txt: '学位，持有'},
        {txt: 'TESOL证书(证书编号: 375453)', special: true},
        {txt: '，他有着丰富的线上教学经验，他的课堂乐趣十足，并且他总是可以和学员变成最好的朋友。许多学生都在Uki老师的课堂里找到了对英语的自信心。'}
      ]
  },
  {
      name: 'Sedina',
      descArr: [
        {txt: 'Sedina老师拥有'},
        {txt: "Master's", special: true },
        {txt: '学位，持有'},
        {txt: 'TEFL证书(证书编号: 1582349PE)', special: true},
        {txt: '，有丰富的教学经验，不仅在当地学校有3年的教学经验。在线上也有2年的经验。老师非常的耐心，幽默，和Sedina老师的课是轻松的，有趣的，有效的。'}
      ]
  },
  {
      name: 'Ivana D',
      descArr: [
        {txt: 'Ivana D老师拥有英语专业'},
        {txt: "Master's", special: true },
        {txt: '学位，持有'},
        {txt: 'TEFL证书(证书编号: 1513448PE)', special: true},
        {txt: '，超过两年线上英语教学的经验。老师富有耐心，爱笑，很有激情，相信会给孩子带来不同的英语学习体验！'}
      ]
  },
  {
      name: 'Letitia',
      descArr: [
          {txt: 'Letitia老师持有'},
          {txt: "TEFL证书(证书编号: ITTT0016100 6)", special: true },
          {txt: '，她来自美国纽约，有6年的英语教学经验。老师非常热爱教学事业，善于创造轻松有趣的上课环境，让孩子们可以愉快的学习英语。'}
      ]
  },
  {
      name: 'Marina',
      descArr: [
          {txt: 'Marina老师拥有英语语言学'},
          {txt: "Bachelor's", special: true },
          {txt: '学位，持有'},
          {txt: 'TESOL/TEFL', special: true},
          {txt: '资质，目前硕士在读，拥有4年多教学经验。老师认为语言无界限，教学中可以跟学生互相学习，擅长根据学生的具体需求调整教学策略。'}
      ]
  },
  {
      name: 'Jenna',
      descArr: [
          {txt: 'Jenna老师持有'},
          {txt: "TEFL", special: true },
          {txt: '证书，而且获得了儿童心理学和早期儿童发展与学习的大学文凭。喜爱孩子的她第一节课得到的评价就是“这是我上过最轻松最享受的课”。'}
      ]
  },
  {
      name: 'Nicholas K',
      descArr: [
          {txt: 'Nicholas K老师持有'},
          {txt: "TEFL证书(证书编号: 100-0010597)", special: true },
          {txt: '，且拥有丰富的在线英语教学经验。他幽默风趣，擅长语法和日常对话。课堂上注重以学生为中心，同时也注重课堂的趣味性和有效性。'}
      ]
  },
  {
      name: 'Martin G',
      descArr: [
          {txt: 'Martin G老师持有'},
          {txt: "TEFL证书(证书编号: 100-0012566)", special: true },
          {txt: '，而且有数年线上线下教学经验。擅长陪伴型教学，专攻英语听说技巧，帮助很多的小朋友克服了不敢开口的难题。'}
      ]
  },
  {
      name: 'Michael Curtis',
      descArr: [
          {txt: 'Michael老师持有'},
          {txt: "TESOL证书(证书编号: 100-13910 0)", special: true },
          {txt: '，他来自美国，善于引导，经验丰富。'}
      ]
  },
  {
      name: 'ChrisR',
      descArr: [
          {txt: 'Chris R老师持有'},
          {txt: "TEFL证书(证书编号: 20016418)", special: true },
          {txt: '，他来自加拿大，有多年的英语教学经验，学生涵盖成人和青少年，专业负责。老师非常热爱教学事业，因材施教，寓教于乐。希望学员在轻松愉悦的环境中开心学习，不断进步。'}
      ]
  },
  {
      name: 'Kirsten Everton',
      descArr: [
          {txt: 'Kirsten E老师拥有'},
          {txt: "Bachelor's", special: true },
          {txt: '学位，持有'},
          {txt: 'TEFL证书(证书编号: 1557084PE)', special: true},
          {txt: '，拥有ESL教学资格及三年的ESL线上教学经验。热爱教育，擅长纠错，道具丰富，善于根据学员水平调整课堂节奏，课堂气氛活跃。'}
      ]
  },
  {
      name: 'Nicola Port',
      descArr: [
          {txt: 'Nicola老师持有'},
          {txt: "TEFL证书(证书编号: 1597692PE)", special: true },
          {txt: '，她来自新西兰，国际关系硕士毕业，有四年多的ESOL教学经验。她很喜欢把自己会的东西教给孩子，通过语言的传递，让学生练好口语掌握好知识。'}
      ]
  },
  {
      name: 'Brenda',
      descArr: [
          {txt: 'Bren老师具有'},
          {txt: "TEFL TESOL", special: true },
          {txt: '教学资格，她是一位非常有趣的老师，她的课堂总是充满精彩与惊喜！！而且她还是一名高中教师，在教学方面非常的有经验。'}
      ]
  }
]

// 家长信息
export const parentArr = [
  {
    name: 'Candy妈妈',
    descArr: [
      {txt: '大枣口语陪练课，陪练师1对1陪孩子练习，让孩子提升听力的同时，还能把学过的知识点消化好。没有push营销的打扰，陪练老师也不会只夸奖不纠错。'},
      {txt: "这里没有顾问催我缴费，陪练老师及时发现的问题和授课老师讲的一样。", special: true }
    ]
  },
  {
    name: 'David妈妈',
    descArr: [
      {txt: '在大枣已经练习了几个月的口语了，前不久'},
      {txt: "遇到一个外国人问路，孩子毫不紧张从容淡定的为他指路", special: true },
      {txt: '，看着其他路人投来羡慕的目光，我为自己的孩子骄傲，为当初选择了大枣而感到开心。'}
    ]
  },
  {
    name: 'Sally爸爸',
    descArr: [
      {txt: '和孩子一起上过几节口语陪练课，对于口语不好的妈妈真好比及时雨！陪练老师都是发音纯正的外国人，1对1陪练的时候非常'},
      {txt: "有耐心", special: true },
      {txt: '，各种鼓励孩子大胆开口，引导孩子完成每一次练习，给大枣口语陪练点赞！'}
    ]
  },
  {
    name: 'Lucas爸爸',
    descArr: [
      {txt: '特别喜欢大枣的口语课，可以选自己学过的内容找口语老师陪练。陪练老师还能针对孩子的发音和语言表达方面的问题给出纠正。现在孩子既敢自信的表达，'},
      {txt: "不再是“哑巴英语”，极好的提高了英语的口语运用能力", special: true },
      {txt: '！'}
    ]
  }
]

