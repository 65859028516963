/*global NODE_ENV*/
/*
 * 下载页
 */
import React from 'react'
import '@scss/download';
import request from "@common/request_n";
import lightToast from '@controls/lightToast';
import { judgeToMobile } from '@common/util';

judgeToMobile()
export default class Home extends React.Component {
  constructor() {
    super()
    this.state = {
      winDownUrl: 'https://peilian-material.oss-cn-hangzhou.aliyuncs.com/pc/dazaoKouyu/dazaoStudentInstaller_0.0.4.exe',
      macDownUrl: 'https://peilian-material.oss-cn-hangzhou.aliyuncs.com/pc/dazaoKouyu/dazao_release.dmg',
      androidPadDownUrl: 'https://oss-cdn.dazaokouyu.com/android/dazao_1.0.0.apk'
    }

    this.fixELe = React.createRef();
  }

  async getPlatFormUrl () {
    let resp = {}
    try {
      resp = await request({
        method: 'get',
        url: '/version/platform/list?clientId=0',
      });
    } catch (err) {
      console.error(err);
      return lightToast(err && err.message || '获取下载链接信息失败');
    }
    let { code, data } = resp;

    if (code !== 200) {
      return lightToast(data.message || '获取下载链接信息失败');
    }

    // 【ios学生端不需要管】
    // 1:iOS学生端
    // 2:ANDROID学生端
    // 3:WINDOWS学生端
    // 5:MAC学生端
    // 4:WINDOWS老师端
    // 6:MAC老师端

    let arr = resp.data || []
    let winDownUrl, macDownUrl, androidPadDownUrl;
    let winDown = arr.find((item) => {
      return item.platform === 3 // WINDOWS学生端
    })

    if (winDown && winDown.updateUrl) {
      winDownUrl = winDown.updateUrl
    }

    //
    let macDown = arr.find((item) => {
      return item.platform === 5 // MAC学生端
    })

    if (macDown && macDown.updateUrl) {
      macDownUrl = macDown.updateUrl
    }
    //

    let androidPadDown = arr.find((item) => {
      return item.platform === 2 // ANDROID学生端
    })

    if (androidPadDown && androidPadDown.updateUrl) {
      androidPadDownUrl = androidPadDown.updateUrl
    }

    console.error(winDownUrl, macDownUrl, androidPadDownUrl)

    this.setState({
      [winDownUrl ? 'winDownUrl' : '']: winDownUrl,
      [macDownUrl ? 'macDownUrl' : '']: macDownUrl,
      [androidPadDownUrl ? 'androidPadDownUrl' : '']: androidPadDownUrl,
    })

  }

  // 处理底部广告
  dealWithFixEle = () => {
    let { auditionFixed } = this.state;
    if (this.isInSight()) {

      auditionFixed && this.setState({
        auditionFixed: false
      })
    } else {
      !auditionFixed && this.setState({
        auditionFixed: true
      });
    }
  }

  handleScroll = (e) => {
    let { scrollTop } = this.state;
    let prevScrollTop = scrollTop;
    let curScrollTop = document.documentElement.scrollTop;

    if (curScrollTop === 0) {
      this.setState({
        scrollTop: 0
      });
      return
    }

    this.dealWithFixEle()

    if (prevScrollTop > 0) return;

    curScrollTop > 0 && this.setState({ scrollTop: curScrollTop });
  }

  componentDidMount () {
    this.getPlatFormUrl()
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnMount () {
    window.removeEventListener('scroll', this.handleScroll);
  }

  //是否在视窗内
  isInSight = () => {
    // left, top, right, bottom
    // const rect = this.fixELe.current.getBoundingClientRect();
    let $ele = document.querySelector('.footer');
    if (!$ele) {
      return false
    }
    const rect = $ele.getBoundingClientRect();

    return !(
      rect.top > window.innerHeight // top 不在视窗
      || rect.bottom < 0 // bottom 不在
      || rect.left > window.innerWidth
      || rect.right < 0
    );
  }

  // 顶部的立即购买点击，跳转到双价区域
  // topClickbuy = () => {
  //   // let $dom = document.querySelector(`.buyCurse`);
  //   let $dom = document.querySelector(`.scroll_target`);
  //   $dom && $dom .scrollIntoView();
  // }

  // 头部信息
  getHeaderTmp = () => {
    let { scrollTop } = this.state;
    return <div className={`header ${scrollTop > 0 ? 'header-scroll' : ''} `}>
      <div class="headInner">
        <div class="logoD">
          <img class="logo" src={require('../images/LOGO@2x.png')} />
        </div>
        <div class="topNav">
          <a href="/"><div class="nav" onClick={this.toHomePage} >首页</div></a>
          <a><div class="nav">软件下载</div></a>
          {/* <a href="#/findMiddle"><div class="nav">代理招募</div></a> */}
          {/* <a href="/comBg.html"><div class="nav">关于我们</div></a> */}
        </div>
        {/* <div class="buyBtn" onClick={this.topClickbuy} >立即购买</div> */}
      </div>
    </div>
  }

  //返回
  goback = () => {
    this.setState({
      buyNum: 0
    });
  }

  // 因未登录，提交时重新打开登录弹窗
  popLoginDialog = () => {
    this.setState({
      loginDgVisible: true
    });
  }

  // 关闭登录注册弹窗
  closeLoginDg = () => {
    this.setState({
      loginDgVisible: false
    });
  }

  // home页
  toHomePage = () => {
    this.setState({
      ...this.initData
    });
  }

  render () {
    let headerTmp = this.getHeaderTmp();
    let { winDownUrl, macDownUrl, androidPadDownUrl } = this.state

    return <div class="container_download">
      {headerTmp}
      <div class="topCtn"><img class="topTxtDesc" src={require('../images/download/dtopTxtDesc.png')} /></div>

      <div>
        <img class="downtitle" src={require('../images/download/downtitle.png')} />
      </div>

      <div className="down_icon_v">
        <div className="down_icon_item">
          <img class="windows" src={require('../images/download/windows.png')} />
          {/* <a href="https://peilian-material.oss-cn-hangzhou.aliyuncs.com/pc/dazaoKouyu/dazaoStudentInstaller_0.0.4.exe">
            <div className="down_btn">立即下载</div>
          </a> */}
          <a href={`${winDownUrl ? winDownUrl : 'https://peilian-material.oss-cn-hangzhou.aliyuncs.com/pc/dazaoKouyu/dazaoStudentInstaller_0.0.4.exe'}`}>
            <div className="down_btn">立即下载</div>
          </a>

          {/* <a href="https://oss-cdn.dazaokouyu.com/client_download/大枣口语_setup_1.5.0.exe">
            <div style={{marginTop: '0.2rem'}} className="down_btn">立即下载</div>
          </a> */}
        </div>
        <div className="down_icon_item">
          <img class="mac" src={require('../images/download/mac.png')} />
          {/* <a href="https://oss-cdn.dazaokouyu.com/client_download/大枣口语_mac_1.5.0.dmg"><div className="down_btn">立即下载</div></a> */}
          <a href={`${macDownUrl ? macDownUrl : 'https://peilian-material.oss-cn-hangzhou.aliyuncs.com/pc/dazaoKouyu/dazao_release.dmg'} `}><div className="down_btn">立即下载</div></a>
        </div>
        <div className="down_icon_item">
          <img class="ipad" src={require('../images/download/ipad.png')} />
          <a href="https://apps.apple.com/cn/app/id1492889328"><div className="down_btn">立即下载</div></a>
        </div>
        <div className="down_icon_item">
          <img class="ipad" src={require('../images/download/andriod_pad.png')} />
          {/* <a href="https://oss-cdn.dazaokouyu.com/android/dazao_1.0.0.apk"><div className="down_btn">立即下载</div></a> */}
          <a href={`${androidPadDownUrl ? androidPadDownUrl : 'https://oss-cdn.dazaokouyu.com/android/dazao_1.0.0.apk'}`}><div className="down_btn">立即下载</div></a>
        </div>
      </div>


      <div className="steps">
        <img src={require('../images/download/steps.png')} />
      </div>
      <div class="footer" ref={this.fixELe}>
        <div class="footerInner">
          <div class="fLeft">
            <div><img className="logoB" src={require('../images/redLogo@2x.png')} /></div>
            <div><img className="weixin" src={require('../images/weixin.png')} /></div>
          </div>
          <div class="fRight">
            <div class="aboutMe">
              <div>大枣口语陪练</div>
              <a href="/"><div>首页</div></a>
              <a><div>软件下载</div></a>
              {/* <a><div>代理招募</div></a> */}
              {/* <a href="/comBg.html"><div>关于我们</div></a> */}
            </div>
            <div class="partner">
              <div>合作伙伴</div>
              <a href="https://www.abc360.com/" target="_blank"><div>abc360英语</div></a>
              <a href="https://www.landi.com/" target="_blank"><div>兰迪少儿英语</div></a>
            </div>
            <div class="connectUs">
              <div>联系我们</div>
              {/* <div>客服电话：0571-22931337（工作日09:00-21:00）</div> */}
              <div>杭州市滨江区江虹路459号英飞特大厦A座13楼</div>
            </div>
          </div>
          <div class="clear"></div>
          {/* 暂时去掉 */}
          <div class="copyRight">
            <div class="hrHor">
            </div>
            <div class="cpLeft">
              <div>Copyright © 2020 杭州瓜蕉科技有限公司 All Rights Reserved.</div>
              <div className="beiancode">
                <a target="_blank" href="https://beian.miit.gov.cn">浙ICP备19047149号-1</a>
                <div className="beian">
                  <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802010959" className="beian_a">
                    <img src={require(`../images/beian.png`)} className="beian_img" />
                    <p className="beian_txt">浙公网安备 33010802010959号</p>
                  </a>
                </div>

              </div>
            </div>
            {/* <div class="cpRight">
              <span>中国认证联盟认证</span>
              <span>网站信用认证</span>
              <span>安全联盟认证</span>
              <span>浙公网安备 33010802003394号</span>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  }
}
