/*
 * 忘记密码
 */
import React from 'react'
import lightToast from '@controls/lightToast'
import request from "@common/request";
import './index.scss';

export default class extends React.Component {
  constructor() {
    super()
    this.state = {

      sending: false,
      second: 60,
      smsTxt: '获取验证码'
    }

    this.phoneReg = /^1\d{10}$/
    this.passwdReg = /^[a-zA-Z0-9]{6,30}$/
    this.codeReg = /^\d{6}$/

    this.mobile = '';
    this.passwd = '';
    this.code = '';

    this.resetPwd = this.resetPwd.bind(this);
    this.getVertiCode = this.getVertiCode.bind(this);
  }

  //输入
  handleInput (inputType, e) { //mobile, code , passwd
    let { value } = e.target;
    this[inputType] = value.trim();
  }

  countDown() {
    const { second } = this.state
    if (second > 0) {
      let time = second
      time--
      this.setState({
        smsTxt: `${time}s后获取`,
        second: time
      })
      this.timer = setTimeout(() => {
        this.countDown()
      }, 1000)
    } else {
      clearTimeout(this.timer)
      this.setState({
        second: 60,
        sending: false,
        smsTxt: "获取验证码",
      })
    }
  }

  // 重置密码
  async resetPwd() {
    let code = this.code;
    let passwd = this.passwd;
    let mobile = this.mobile;
    // 验证验证码和密码
    if (!this.passwdReg.test(passwd)) {
      return lightToast('密码不符合要求')
    }

    if (!this.codeReg.test(code)) {
      return lightToast('验证码格式错误')
    }

    let resp = await request({
      method: 'post',
      url: "/gateway/route?action=student.passwd.forget",
      data: {
        mobile: parseInt(mobile),
        code,
        passwd
      }
    });

    if (resp && resp.code === 0 ) {
      lightToast('密码更新成功')
      setTimeout(() => {
        this.props.reLogin();
      }, 800);
    }
  }

  // 获取验证码
  async getVertiCode() {
    let resp, mobile = this.mobile;

    if (this.state.sending) {
      return;
    }
    if (!this.phoneReg.test(mobile)) {
      return lightToast('请输入正确手机号');
    }

    try {
      resp = await request({
        method: 'post',
        url: '/gateway/route?action=sms.code.get',
        data: {
          mobile: parseInt(mobile),
          scene: 102,
          check_mobile: 0
        }
      });
    } catch (err) {
      console.log(err)
      return;
    }

    if (resp.code === 0) {
      lightToast('验证码发送成功')
      this.setState({
        sending: true
      });
      this.countDown();
    } else {
      this.setState({
        sending: false,
      });

      lightToast(resp.message || '验证码发送失败，请检查手机号');
    }
  }

  render() {
    const { smsTxt, sending } = this.state;

    return (
      <div class="forgetPwdCtn">
        <div class="phoneD">
          <img src={require('../../images/icon_iphone2x.png')}/>
          <input type="number" placeholder="手机号" onInput={this.handleInput.bind(this, 'mobile')}/>
        </div>
        <div class="aboutPhone">
          <div class="vertifiCode">
            <img src={require('../../images/icon_code2x.png')}/>
            <input type="text" placeholder="验证码" onInput={this.handleInput.bind(this, 'code')}/>
          </div>
          <div className={`getVertiCode ${sending ? 'gray' : ''} `}
            onClick={this.getVertiCode}
            onInput={this.handleInput.bind(this, 'code')}>{smsTxt || '获取验证码'}</div>
        </div>
        <div class="pwd">
          <img src={require('../../images/icon_key2x.png')}/>
          <input type="text" className="pwd_input" placeholder="密码" onInput={this.handleInput.bind(this, 'passwd')}/>
        </div>
        <div class="regBtn" onClick={this.resetPwd}>重置密码</div>
      </div>
    )
  }
}
