/*
 * 购买课程
 */
import React from 'react';
import './index.scss';
import lightToast from '@controls/lightToast';
import { getUserInfo, debounce, getOS } from '@common/util';
import request from "@common/request";
const CHECK_PAY_TIME = 1500;

export default class extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      qrImg: '', // 二进制字节流
      order_no: '', //订单no
      curStep: 'first', // 当前步骤, first second thrid
      hasPaySuccess: false, // 是否已付费成功
    }
    this.submit = debounce(this.submit).bind(this);
  }

  // 提交
  async submit () {
    let { itemInfo } = this.props;
    let userInfo = getUserInfo();
    let resp;

    if (!userInfo || !userInfo.phoneNo) { // 如果当前没有登录的话，需要弹出登录注册框
      return this.props.popLoginDialog && this.props.popLoginDialog();
    }
    lightToast('订单提交中，请稍后~');

    try {
      resp = await request({
        method: 'post',
        url: '/gateway/route?action=goods.pay.qr',
        data: {
          sid: userInfo.sid,  // 学生ID
          iid: itemInfo.iid  //商品ID来自于商品信息结果中的iid
        }
      });
    } catch (err) {
      return lightToast(err && err.message || '获取支付信息失败');
    }
    let { code, data } = resp;

    if (code !== 0) {
      return lightToast(data.message || '获取支付信息失败，请重试');
    }

    this.setState({
      curStep: 'second',
      qrImg: data.qr_info,
      order_no: data.order_no
    });

    setTimeout(() => {
      this.payCheck();
    }, CHECK_PAY_TIME);
  }

  // 检查是否支付成功
  async payCheck () {
    let paySuccess = await this.isPaidSuccess();

    if (paySuccess) {
      lightToast('付款成功~');
      this.setState({
        curStep: 'third'
      });
    } else {
      setTimeout(() => {
        this.payCheck();
      }, CHECK_PAY_TIME);
    }
  }

  //订单支付成功检查
  async isPaidSuccess () {
    let resp, userInfo = getUserInfo();
    let { order_no } = this.state;
    try {
      resp = await request({
        method: 'post',
        url: '/gateway/route?action=order.pay.check', // 请求对应的第二个页面的支付信息
        data: {
          sid: userInfo.sid,  // 学生ID
          order_no: order_no  //商品ID来水于商品信息结果中的iid
        }
      });
    } catch (err) {
      lightToast(err && err.message || '获取支付信息失败');
      return false;
    }
    let { code, data } = resp || {};

    return code === 0 && data && data.is_paid === 1;
  }

  // 返回首页
  goback = () => {
    let { curStep } = this.state;
    if (curStep === 'first') {
      return this.props.goback && this.props.goback();
    }

    this.setState({
      curStep: curStep === 'second' ? 'first' : 'second'
    })
  }

  render () {
    let { userInfo = {}, itemInfo } = this.props;
    let { curStep, qrImg } = this.state;
    let enviv = getOS().toLocaleLowerCase(); // iPhone + ipad + mac当前的平台
    return (
      <>
        <div className="buyCurseOuter" data-value={curStep}>
          <div className="titleD">
            <img src={require('../../images/icon_back2x.png')} onClick={this.goback} />
            <span>购买课程</span>
          </div>
          <div className="flowD">
            {
              userInfo.phoneNo ? <div className="curAccount">
                <div className="curInn">
                  <div>当前账号</div>
                  <div>{userInfo.phoneNo}</div>
                </div>
              </div> : null
            }
            <div className="flow" data-value={curStep}>
              <div className="line first second third" ></div>
              <div className="circle first second third" data-value={curStep}>1</div>
              <div className="line second third" data-value={curStep}></div>
              <div className="circle second third" data-value={curStep}>2</div>
              <div className="line third" data-value={curStep}></div>
              <div className="circle third" data-value={curStep}>3</div>
              <div className="line third" data-value={curStep}></div>
            </div>
            <div className="flowName">
              <div className="line"></div>
              <div className="name">核对订单</div>
              <div className="line"></div>
              <div className="name">订单支付</div>
              <div className="line"></div>
              <div className="name">软件下载</div>
              <div className="line"></div>
            </div>
          </div>

          <div className="firstPage">
            <div className="cursDesc">
              <div className="deailTxt">课程详情</div>
              <div className="curseDescTable">
                <table>
                  <tr>
                    <td>套餐类型</td>
                    <td>陪练师类型</td>
                    <td>上课频率</td>
                    <td>班级模式</td>
                    <td>价格</td>
                  </tr>
                  <tr>
                    <td className="red">{itemInfo.cls_hour || 96}课时</td>
                    <td className="grayF">{!itemInfo.remark ? '' : itemInfo.remark[0].substr(0, itemInfo.remark[0].length - 3)}</td>
                    <td className="grayF">随时约，随时上</td>
                    <td className="grayF">1对1</td>
                    <td className="red">￥{itemInfo.sale_price}</td>
                  </tr>
                  <tr>
                    <td colSpan="5"><span>应付金额：</span><span className="red fs30">￥{itemInfo.sale_price}</span></td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="submit" onClick={this.submit}>提交订单</div>
          </div>

          <div className="secondPage">
            <div className="title">请使用支付宝扫码付款</div>
            <div className="qrDiv">
              {/* <img className="aliImg" src={require(`../../images/alipay_img.png`)}/> */}
              <img className="qrImg" src={qrImg} />
            </div>

          </div>

          <div className="thirdPage">
            <div className="desc">付款完毕，下载软件登录后就能上课啦！</div>
            <div className="downIcon">
              <div >
                <a href="https://oss-cdn.dazaokouyu.com/client_download/大枣口语_setup_1.4.5.exe"><img src={require('../../images/windows@2x.png')} />
                  <div className="down_txt">Windows下载</div>
                </a>
              </div>
              {
                enviv === 'win' ? <>
                  <div className="ipad">
                    <a href="https://apps.apple.com/cn/app/%E5%A4%A7%E6%9E%A3%E5%9C%A8%E7%BA%BF%E5%A4%96%E6%95%99%E5%8F%A3%E8%AF%AD%E8%AF%BE/id1459062504" target="_blank">
                      <img src={require('../../images/iPad@2x.png')} />
                    </a>
                  </div>
                  {/* <div className="iphone">
                    <a href="https://apps.apple.com/cn/app/%E5%A4%A7%E6%9E%A3%E5%9C%A8%E7%BA%BF%E5%A4%96%E6%95%99%E5%8F%A3%E8%AF%AD%E8%AF%BE/id1459062504" target="_blank">
                      <img src={require('../../images/iPhone@2x.png')}/>
                    </a>
                  </div> */}
                </> : <>
                  <div className="ipad">
                    <img src={require('../../images/iPad@2x.png')} />
                    <div className="down_txt">iPad下载</div>
                    <img className="downQrcode" src={require('../../images/downQrcode.png')} />
                  </div>
                  {/* <div className="iphone">
                  <img src={require('../../images/iPhone@2x.png')}/>
                  <img className="downQrcode" src={require('../../images/downQrcode.png')}/>
                </div> */}
                </>
              }
              {/* <div className="android">
                <img src={require('../../images/android@2x.png')}/>
                <div className="tips">开发中，敬请期待</div>
              </div> */}
            </div>
          </div>
        </div>
      </>
    )
  }
}
